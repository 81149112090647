import React from "react";
import {Box, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";

interface StaffCardProps {
  avatarURL?: string;
  name: React.ReactNode;
  title: React.ReactNode;
}

export default function StaffCard(props: React.PropsWithChildren<StaffCardProps>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Box
      sx={{
        bgcolor: "#C3E2FF",
        width: "inherit",
        maxWidth: "900px",
        padding: "40px",
        borderRadius: "40px",
        m: { xs: 2, md: 0 }
      }}
    >
      <Grid
        container
        spacing={2}
        {
          ...isMobile && {
            direction: "column",
            alignItems: "center"
          }
        }
      >
        {/*<Grid item xs={4}>*/}
          {/*<img src={props.avatarURL} style={{ height: "auto", width: "100%" }} />*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <Typography variant="h3" align={isMobile ? "center" : "left"} sx={{mb: 1}}>{props.name}</Typography>
          <Typography variant="h5" color="primary" align={isMobile ? "center" : "left"} sx={{mb: 2}}>{props.title}</Typography>
          <Typography variant="h4" align={isMobile ? "center" : "left"}>{props.children}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}